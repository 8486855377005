import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Box,
  TextField,
  IconButton,
  Typography,
} from "@mui/material";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useEffect, useState } from "react";
import ContactModule from "app/mmEsoft/module/ContactModule";
import SessionModule from "app/mmEsoft/module/SessionModule";
import useGetAllCanditates from "../hooks/useGetAllCanditates";

const ContactList = ({ open, setOpen, batchId, Swal, fetchCandidates }) => {
  const [contactList, setContacts] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  useEffect(() => {
    if (open) {
      setSearchTerm("");
      setPageNumber(0);
      fetchAllContacts(0);
    }
  }, [open]);

  const fetchAllContacts = (page) => {
    ContactModule.getAllContacts(page, 10, (response) => {
      if (response.status === "success") {
        setContacts(response?.data);
      } else {
        console.log("Error fetching all contacts");
      }
    });
  };

  // const { fetchCandidates } = useGetAllCanditates(batchId);

  const handleChange = (e) => {
    if (e.target.value)
      ContactModule.searchContactTerm(e.target.value, (response) => {
        if (response.status === "success") setContacts(response?.data);
        else console.log("error at search term contacts ");
      });
  };

  useEffect(() => {
    ContactModule.getAllContacts(0, 10, (response) => {
      if (response.status === "success") {
        setContacts(response?.data);
      } else console.log("errafor at get all contacts");
    });
  }, []);

  // Handle pagination
  const pageIncre = () => {
    setPageNumber(pageNumber + 1);
    ContactModule.getAllContacts(pageNumber + 1, 10, (response) => {
      if (response.status == "success") {
        setContacts(response?.data);
      } else {
        console.log("error while getting the Contact ");
      }
    });
  };

  const pageDecre = () => {
    setPageNumber(pageNumber - 1);
    ContactModule.getAllContacts(pageNumber - 1, 10, (response) => {
      if (response.status == "success") {
        setContacts(response?.data);
      } else {
        console.log("error while getting the Contact ");
      }
    });
  };

  const addContactToCandidate = (
    contactId,
    contactName,
    contactEmail,
    contactPhoneNumber
  ) => {
    const data = {
      id: contactId,
      name: contactName,
      email: contactEmail,
      contact: contactPhoneNumber,
    };

    SessionModule.addContactToCandidate(batchId, data, (response) => {
      if (response.status === "success") {
        fetchCandidates(batchId, 0, 10);
        Swal.fire({
          icon: "success",
          title: "Student has been added successfully.",
        });

        setOpen(!open);
      } else {
        Swal.fire({
          icon: "error",
          title:
            response.data === "already exists"
              ? "Student already exists"
              : "Student already exists",
        });
        console.log("error while adding contact to candidates");

        setOpen(!open);
      }
    });
  };

  return (
    <Dialog open={open} onClose={() => setOpen(!open)} maxWidth="lg">
      <DialogTitle>Add students to batch</DialogTitle>
      <DialogContent>
        <TextField
          placeholder="Name/Email/Phone Number"
          sx={{ width: "100%" }}
          onChange={handleChange}
        />
        <TableContainer>
          <Table sx={{ minWidth: 700 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone Number</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {contactList.length == 0 ? (
                <Typography
                  variant="h3"
                  sx={{
                    color: "#212121",
                    textAlign: "center",
                  }}
                >
                  No data
                </Typography>
              ) : (
                contactList.map((contact) => {
                  return (
                    <TableRow key={contact?.id}>
                      <TableCell>{contact?.name}</TableCell>
                      <TableCell>{contact?.email}</TableCell>
                      <TableCell>{contact?.phoneNumber}</TableCell>
                      <TableCell>
                        <Button
                          onClick={() =>
                            addContactToCandidate(
                              contact?.id,
                              contact?.name,
                              contact?.email,
                              contact?.phoneNumber
                            )
                          }
                          variant="contained"
                        >
                          Add
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ marginTop: 2, width: "100%" }}
        >
          {pageNumber > 0 ? (
            <IconButton onClick={pageDecre}>
              <NavigateBeforeIcon />
            </IconButton>
          ) : null}
          <Typography
            sx={{
              fontSize: "18px",
              borderRadius: "100px",
            }}
          >
            {pageNumber + 1}
          </Typography>
          {contactList.length < 10 ? null : (
            <IconButton onClick={pageIncre}>
              <NavigateNextIcon />
            </IconButton>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => setOpen(!open)}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ContactList;
